import React from "react";
import { Fab, Grid } from "../../../../../../node_modules/@mui/material/index";

const ActionButton = ({ actionLabel, onAction }) => {
  return (
    <Grid
      sx={{
        "& > :not(style)": { width: "100%" },
        position: "sticky",
        bottom: 0,
        p: "20px",
        marginBottom: "-20px",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "512px",
        width: "100%",
        zIndex: 2,
      }}
    >
      <Fab
        variant="extended"
        size="medium"
        onClick={onAction}
        sx={{
          color: "#fff",
          backgroundColor: "#000",
          textTransform: "none",
          p: "12px 24px",
          borderRadius: "999px",
          fontSize: "16px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "&:hover": {
            backgroundColor: "#000",
          },
        }}
      >
        {actionLabel}
      </Fab>
    </Grid>
  );
};

export default ActionButton;
