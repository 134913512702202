import CloseButton from "../BottomSheet/Details/components/CloseButton";
import styles from "./campaign.module.css";
import ActionButton from "./components/ActionButton";
import { motion } from "framer-motion";

const CampaignPopup = ({ image, onClose, onAction, actionLabel }) => {
  const handleContentClick = (event) => {
    // prevent closing when clicking on the promotion campaign
    event.stopPropagation();
  };

  const handleClose = () => {
    onClose();
  };
  return (
    <motion.div
      className={styles.popupOverlay}
      onClick={handleClose}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeInOut" }}
    >
      <div className={styles.popupContent} onClick={handleContentClick}>
        {/* Close Button */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <CloseButton setOpen={onClose} />
        </div>

        {/* Campaign Image */}
        <img src={image} alt="Campaign" className={styles.campaignImage} />

        {/* Action Button */}
        <div className={styles.actionButtonWrapper}>
          <ActionButton onAction={onAction} actionLabel={actionLabel} />
        </div>
      </div>
    </motion.div>
  );
};

export default CampaignPopup;
