import { db } from "../FirebaseConfig/Firebase";
import { collection, query, where, getDocs } from "firebase/firestore";

//Analytics
import { monitoring } from "@Ethical-Algorithm/reactjs-ea-logger-kit";
import { secondsToMillis } from "utils/dateUtils";

//Get company document by id
export const fetchCampaignForDate = async (companyID, nowDate, products) => {
  try {
    // matching the companyID and valid date
    const campaignsQuery = query(
      collection(db, "campaigns"),
      where("companyID", "==", companyID)
    );

    const querySnapshot = await getDocs(campaignsQuery);

    const campaigns = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Filter campaigns
    const filteredCampaigns = campaigns.filter((campaign) => {
      const startDate = secondsToMillis(campaign.startDate);
      const endDate = secondsToMillis(campaign.endDate);

      const isDateValid = startDate <= nowDate && endDate >= nowDate;
      const isMenuItemValid = products.some(
        (product) => product.id === campaign.menuItemID
      );

      const isValid = isDateValid && isMenuItemValid;

      return isValid;
    });

    if (filteredCampaigns.length === 0) {
      return null;
    }
    // Assume only one campaign
    const validCampaign = filteredCampaigns[0];

    return validCampaign;
  } catch (error) {
    monitoring.error(error);
    console.error(`Error fetching campaign: ${error.message}`);
    throw error;
  }
};
